import { useRecoilState, useRecoilValue } from 'recoil';
import {
  isMobileState,
  oiFullscreenState,
  oiHiroSymsState,
  oiIntradayFilterPrice,
  oiIntradayInvertedState,
  oiIntradayParquetKeys,
  oiIntradayPriceBoundsState,
  oiNegativeGammaColorState,
  oiPositiveGammaColorState,
  oiPriceCandleDurationState,
  oiScaleRangeState,
  oiShowColorScaleState,
  oiShowContourLinesState,
  oiShowGexZeroDteState,
  oiShowKeyLevelsState,
  oiStatsLookbackDaysState,
  oiStrikeBarsTrackerEnabledState,
  oiStrikeBarTypeState,
  oiUseWhiteMode,
  screenWidthWithoutSidebarState,
  timezoneState,
} from '../../states';
import dayjs, { Dayjs } from 'dayjs';
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Slider,
  Stack,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ET,
  getCurrentDate,
  getDateFormatted,
  getDefaultSlotProps,
  getQueryDate,
  greekForLense,
  isLatestTradingDay,
  isMarketOpenOnDate,
} from '../../util';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  IntradayGammaLense,
  IntradayStrikeBarType,
  OIEntity,
  OIScaleRange,
  PRICE_BOUNDS,
  TraceSettings,
  ZoomData,
} from '../../types';
import { SGTooltip } from '../../components/core';
import useUserDetails from '../../hooks/user/useUserDetails';
import SettingsPopout from '../../components/shared/SettingsPopout';
import { MuiColorInput } from 'mui-color-input';
import { InfoButton, SGSelect } from '../../components';
import { capitalize } from 'lodash';
import {
  HEATMAP_FIRST_AVAILABLE_DATE,
  INTRADAY_BAR_TITLES,
  IntradayFiltersReadable,
  IntradayShowChartType,
  LAST_TRACE_UPDATE_HOUR_ET,
  PROD_GAMMA_LENSES,
} from '../../config/oi';
import { useSetSym } from '../../hooks';
import { ComboSymbol } from '../../config';
import { ChartSizingButton } from '../../components/shared/ChartSizingButton';
import FullscreenExitSharpIcon from '@mui/icons-material/FullscreenExitSharp';
import FullscreenSharpIcon from '@mui/icons-material/FullscreenSharp';
import { HideSupportStyle } from '../../components/shared/HideSupportStyle';
import { TraceVolGauge } from './TraceVolGauge';
import { ColorMode } from '../../theme';

type IntradayGammaControlsProps = {
  timestamps: dayjs.Dayjs[];
  showAllSettings?: boolean;
  intradayDate: dayjs.Dayjs;
  zoomData: ZoomData | undefined;
  resetZoom: () => void;
  setStrikeBarType: (strikeBarType: IntradayStrikeBarType) => void;
  showChartType: IntradayShowChartType;
  setShowChartType: (chartType: IntradayShowChartType) => void;
  intradaySym: string;
  selectedLense: IntradayGammaLense;
  timestamp: dayjs.Dayjs | null;
  statsData: any;
  gammaAtLastPriceUninverted: number | undefined;
  lastUpdateCheckAt: dayjs.Dayjs | null;
};

const IntradayFiltersTooltip = new Map([
  [IntradayGammaLense.GAMMA, 'Shows gamma values on the chart'],
  [IntradayGammaLense.DELTA, 'Shows delta values on the chart'],
  [
    IntradayGammaLense.DELTA_DIRECTIONAL,
    `Shows the direction of delta at the current price per timestamp and strike`,
  ],
  [
    IntradayGammaLense.GAMMA_DIRECTIONAL,
    `Shows the direction of gamma at the current price per timestamp and strike`,
  ],
  [IntradayGammaLense.GAMMA_COLOR, `Shows the change in gamma per minute`],
  [
    IntradayGammaLense.DELTA_CHARM_DIRECTIONAL,
    `Shows the change in delta buying/selling pressure per minute`,
  ],
  [
    IntradayGammaLense.DELTA_END_DIFF,
    `Shows the change in delta between close and spot/time`,
  ],
]);

const StrikeBarTypeLabels = new Map([
  [IntradayStrikeBarType.GAMMA, `GEX`],
  [IntradayStrikeBarType.OI, 'OI'],
  [IntradayStrikeBarType.OI_NET, 'Net OI'],
  [IntradayStrikeBarType.NONE, 'None'],
]);

const StrikeBarTypeTooltips = new Map([
  [IntradayStrikeBarType.GAMMA, `Show Gamma Exposure in the bar chart`],
  [IntradayStrikeBarType.OI, 'Show Open Interest in the bar chart'],
  [IntradayStrikeBarType.OI_NET, 'Show Net Open Interest in the bar chart'],
  [IntradayStrikeBarType.NONE, 'Hide the strike bar chart'],
]);

const OIENTITY_READABLE = new Map([
  [OIEntity.CUST, 'Customers'],
  [OIEntity.PROCUST, 'Pro Customers'],
  [OIEntity.BD, 'Broker Dealers'],
  [OIEntity.FIRM, 'Firms'],
  [OIEntity.MARKET_MAKER, 'Market Makers'],
]);

export const IntradayGammaControls = ({
  timestamps,
  showAllSettings,
  intradayDate,
  zoomData,
  resetZoom,
  setStrikeBarType,
  showChartType,
  setShowChartType,
  intradaySym,
  selectedLense,
  timestamp,
  gammaAtLastPriceUninverted,
  statsData,
  lastUpdateCheckAt,
}: IntradayGammaControlsProps) => {
  const [filterPrice, setFilterPrice] = useRecoilState(oiIntradayFilterPrice);
  const [parquetKeys, setParquetKeys] = useRecoilState(oiIntradayParquetKeys);
  const [isInverted, setIsInverted] = useRecoilState(oiIntradayInvertedState);
  const [fullscreen, setFullScreen] = useRecoilState(oiFullscreenState);

  const theme = useTheme();
  const defaultSlotProps = getDefaultSlotProps(theme);
  const maxQueryDate = dayjs(getQueryDate(true).format());
  const isMobile = useRecoilValue(isMobileState);
  const tz = useRecoilValue(timezoneState);
  const { saveSgSettings } = useUserDetails();

  const priceBounds = useRecoilValue(oiIntradayPriceBoundsState);
  const posColor = useRecoilValue(oiPositiveGammaColorState);
  const negColor = useRecoilValue(oiNegativeGammaColorState);
  const candleDuration = useRecoilValue(oiPriceCandleDurationState);
  const showKeyLevels = useRecoilValue(oiShowKeyLevelsState);
  const scaleRange = useRecoilValue(oiScaleRangeState);
  const showGexZeroDte = useRecoilValue(oiShowGexZeroDteState);
  const hiroSymsMap = useRecoilValue(oiHiroSymsState);
  const showColorScale = useRecoilValue(oiShowColorScaleState);
  const showContourLines = useRecoilValue(oiShowContourLinesState);
  const statsLookbackDays = useRecoilValue(oiStatsLookbackDaysState);
  const useWhiteMode = useRecoilValue(oiUseWhiteMode);
  const trackerEnabled = useRecoilValue(oiStrikeBarsTrackerEnabledState);
  const strikeBarType = useRecoilValue(oiStrikeBarTypeState);
  const screenWidthWithoutSidebar = useRecoilValue(
    screenWidthWithoutSidebarState,
  );

  const { setParams } = useSetSym();

  const hiroSym = hiroSymsMap.get(intradaySym) ?? intradaySym;

  const horizGap = isMobile
    ? '5px'
    : screenWidthWithoutSidebar > 1000
    ? '20px'
    : '10px';

  let selectedTimestampIndex = timestamps.length - 1;
  for (let i = timestamps.length - 1; i >= 0; i--) {
    if (timestamp?.isSame(timestamps[i], 'second')) {
      selectedTimestampIndex = i;
      break;
    }
  }

  const isLatestTimestamp = selectedTimestampIndex === timestamps.length - 1;
  let suppressStability = false;
  if (selectedTimestampIndex > timestamps.length / 2 && timestamp != null) {
    const etTime = timestamp.tz(ET);
    suppressStability =
      (etTime.hour() === 15 && etTime.minute() >= 30) || etTime.hour() >= 16;
  }

  const datePicker = (
    <SGTooltip
      title="View TRACE data for historical dates. Note that HIRO values are only available for 5 days."
      placement="top"
    >
      <Box sx={{ height: '40px', alignSelf: 'center' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            views={['day']}
            value={intradayDate}
            onChange={(newValue: Dayjs | null) => {
              if (newValue && newValue.isValid()) {
                setParams({ date: getDateFormatted(newValue) });
              }
            }}
            minDate={HEATMAP_FIRST_AVAILABLE_DATE}
            maxDate={maxQueryDate}
            shouldDisableDate={(d) => !isMarketOpenOnDate(d)}
            format="YYYY-MM-DD"
            slotProps={{
              ...defaultSlotProps,
              textField: {
                ...defaultSlotProps.textField,
                style: {
                  width: isMobile ? '100px' : '135px',
                  fontSize: '14px',
                  marginBottom: '12px',
                  marginRight: '5px',
                },
              },
            }}
          />
        </LocalizationProvider>
      </Box>
    </SGTooltip>
  );

  const handleBoundsChange = (
    _evt: Event,
    idx: number | number[],
    _activeThumb: number,
  ) => {
    if (!Array.isArray(idx)) {
      saveSgSettings({ oi: { zoom: PRICE_BOUNDS[idx] } });
    }
  };

  const volGauge = (
    <TraceVolGauge
      statsData={statsData}
      gammaAtLastPriceUninverted={gammaAtLastPriceUninverted}
      parquetKey={parquetKeys[0]}
      width={100}
      height={55}
      fontSize={13}
      suppress={suppressStability}
    />
  );

  const saveChanges = (newSettings: Partial<TraceSettings>) =>
    saveSgSettings({ oi: newSettings });

  const settingsPopout = (
    <Box sx={{ height: '40px', alignSelf: 'center' }}>
      <SettingsPopout
        title="Settings"
        popperID={'intraday-gamma-settings'}
        zIndex={1}
        placement="left-end"
      >
        <Stack direction="row">
          <Box display="flex" flexDirection="column" gap="6px">
            <Typography variant="h4" gutterBottom color="primary">
              Positive {capitalize(greekForLense(selectedLense))} Color
            </Typography>
            <MuiColorInput
              format="hex"
              value={posColor}
              onChange={(value: string) =>
                saveChanges({
                  posColor: value,
                })
              }
              PopoverProps={{
                anchorOrigin: {
                  horizontal: 'left',
                  vertical: 'bottom',
                },
              }}
            />
          </Box>

          <Box display="flex" flexDirection="column" gap="6px">
            <Typography variant="h4" gutterBottom color="primary">
              Negative {capitalize(greekForLense(selectedLense))} Color
            </Typography>
            <MuiColorInput
              format="hex"
              value={negColor}
              onChange={(value: string) =>
                saveChanges({
                  negColor: value === negColor ? undefined : value,
                })
              }
              PopoverProps={{
                anchorOrigin: {
                  horizontal: 'right',
                  vertical: 'bottom',
                },
              }}
            />
          </Box>
        </Stack>

        <Box marginY="5px">
          <FormControl>
            <SGTooltip
              title="Select the color scale range for the Heatmap. Select ‘auto’ to dynamically adjust the coloration, or select another option for the desired level of contrast."
              placement="left"
            >
              <Typography variant="h4" gutterBottom color="primary">
                Scale Range
              </Typography>
            </SGTooltip>

            <RadioGroup
              row
              value={scaleRange}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                saveChanges({
                  scaleRange: event.target.value as OIScaleRange,
                })
              }
            >
              <FormControlLabel
                value={OIScaleRange.AUTO}
                control={<Radio />}
                label="auto"
              />
              <FormControlLabel
                value={OIScaleRange.LOW}
                control={<Radio />}
                label="low"
              />
              <FormControlLabel
                value={OIScaleRange.MEDIUM}
                control={<Radio />}
                label="medium"
              />
              <FormControlLabel
                value={OIScaleRange.HIGH}
                control={<Radio />}
                label="high"
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Box marginY="5px">
          <FormControl>
            <SGTooltip
              title="Select which HIRO symbol to plot on the HIRO line in the chart. S&P Equities sums the HIRO signal for all single stock components of the S&P 500, while S&P 500 combines the HIRO signal for SPX, XSP, SPY, & ES=F."
              placement="left"
            >
              <Typography variant="h4" gutterBottom color="primary">
                HIRO Symbol
              </Typography>
            </SGTooltip>

            <RadioGroup
              row
              value={hiroSym}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                saveChanges({
                  hiroSyms: {
                    ...hiroSymsMap.entries(),
                    [intradaySym]: event.target.value,
                  },
                })
              }
            >
              <SGTooltip title={'Not available premarket'} placement="left">
                <FormControlLabel
                  value={ComboSymbol.SPX_EQUITIES}
                  control={<Radio />}
                  label={ComboSymbol.SPX_EQUITIES}
                />
              </SGTooltip>
              <FormControlLabel
                value={ComboSymbol.SPX}
                control={<Radio />}
                label={ComboSymbol.SPX}
              />
              <FormControlLabel value={''} control={<Radio />} label="None" />
            </RadioGroup>
          </FormControl>
        </Box>

        <Stack direction="row" justifyContent="center">
          <SGTooltip
            title={
              showColorScale
                ? 'Turn off the color scale on the right side.'
                : 'Turn on the color scale on the right side.'
            }
          >
            <FormControlLabel
              control={
                <Switch
                  checked={showColorScale}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    saveChanges({
                      hideColorScale: !event.target.checked,
                    })
                  }
                />
              }
              label={<Typography>Color scale</Typography>}
              labelPlacement="end"
            />
          </SGTooltip>
          <SGTooltip
            title={
              showContourLines
                ? 'Turn off the contour lines on the heatmap.'
                : 'Turn on the contour lines on the heatmap.'
            }
          >
            <FormControlLabel
              control={
                <Switch
                  checked={showContourLines}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    saveChanges({
                      hideContourLines: !event.target.checked,
                    })
                  }
                />
              }
              label={<Typography>Contour lines</Typography>}
              labelPlacement="end"
            />
          </SGTooltip>
        </Stack>

        <Stack gap={3} marginTop="10px" alignItems="center">
          <SGTooltip title="Select a 30, 60, or 90-day look back period for percentile stats, which are shown when hovering over the Strike Plot or Heatmap.">
            <Typography variant="h4" gutterBottom color="primary">
              Statistics Look Back Period
            </Typography>
          </SGTooltip>
          <ButtonGroup
            aria-label="stats lookback settings"
            sx={{ width: '100%' }}
          >
            {[30, 60, 90].map((period) => (
              <Button
                size="small"
                key={period}
                variant={
                  period === statsLookbackDays ? 'contained' : 'outlined'
                }
                sx={{
                  fontSize: isMobile ? 12 : 14,
                  textTransform: 'none',
                  width: '33.3%',
                }}
                onClick={() => saveChanges({ statsLookbackDays: period })}
              >
                {period} days
              </Button>
            ))}
          </ButtonGroup>
        </Stack>

        <Stack direction="row" justifyContent="center" marginTop="10px">
          {theme.colorMode !== ColorMode.LIGHT && (
            <SGTooltip title="Enable/disable light mode for the TRACE heatmap; disabling this toggle will display the heatmap in dark mode.">
              <FormControlLabel
                control={
                  <Switch
                    checked={useWhiteMode}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      saveChanges({
                        useWhiteMode: event.target.checked,
                      })
                    }
                  />
                }
                label={<Typography>Light Chart</Typography>}
              />
            </SGTooltip>
          )}
          <SGTooltip title="Enable three lines for each bar on the Strike Plot indicating the position by strike from 10, 30, and 60 minutes prior.">
            <FormControlLabel
              control={
                <Switch
                  checked={trackerEnabled}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    saveChanges({
                      strikeBarsTrackerDisabled: !event.target.checked,
                    })
                  }
                />
              }
              label={<Typography>Strike Plot Tracker</Typography>}
            />
          </SGTooltip>
        </Stack>
      </SettingsPopout>
    </Box>
  );

  const strikeBarSelectors = (
    <Stack
      direction="row"
      gap={horizGap}
      key="strikeBarSelectors"
      alignItems="center"
    >
      {!isMobile && (
        <ChartSizingButton
          onClick={() => {
            setShowChartType(
              showChartType === IntradayShowChartType.StrikeBars
                ? IntradayShowChartType.Both
                : IntradayShowChartType.StrikeBars,
            );
          }}
          icon={
            showChartType === IntradayShowChartType.StrikeBars ? (
              <FullscreenExitSharpIcon />
            ) : (
              <FullscreenSharpIcon />
            )
          }
          title={
            showChartType === IntradayShowChartType.StrikeBars
              ? 'Minimize the Strike Plot by showing the Heatmap and color scale'
              : 'Maximize the Strike Plot by hiding the Heatmap and color scale'
          }
          buttonSx={{ height: '40px', marginTop: '-1px' }}
        />
      )}
      <SGSelect
        key={'strike_bar_type'}
        label={'Strike Bar Type'}
        multiple={false}
        value={strikeBarType}
        setter={(val) => setStrikeBarType(val)}
        options={[
          IntradayStrikeBarType.GAMMA,
          IntradayStrikeBarType.OI,
          IntradayStrikeBarType.OI_NET,
          IntradayStrikeBarType.NONE,
        ]}
        optionsTextTransform={(s) => StrikeBarTypeLabels.get(s) ?? s}
        tooltipTransform={(s) => StrikeBarTypeTooltips.get(s)!}
        selectTooltipProps={{
          title:
            'Select from GEX, OI, or Net OI to display on the Strike Plot, or hide the chart by selecting None.',
        }}
        sx={{ height: '40px' }}
      />
      <Box
        visibility={
          strikeBarType === IntradayStrikeBarType.NONE ? 'hidden' : 'visible'
        }
        sx={{
          marginTop: isMobile ? undefined : '-4px',
        }}
      >
        <SGTooltip
          title={`Show only 0dte ${StrikeBarTypeLabels.get(
            strikeBarType,
          )!} in the strike bar chart on the left. Note that these values will be 0 after market close.`}
        >
          <FormControlLabel
            control={
              <Switch
                checked={showGexZeroDte}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  saveChanges({
                    showGexZeroDte: event.target.checked,
                  })
                }
              />
            }
            label={
              <Typography>
                0DTE {StrikeBarTypeLabels.get(strikeBarType)!}
              </Typography>
            }
            labelPlacement={'bottom'}
            sx={{ marginX: 0 }}
          />
        </SGTooltip>
        {!isMobile && (
          <InfoButton
            articleKey={`TRACE-STRIKE-PLOT`}
            tooltipTitle="Learn more about the TRACE Strike Plot."
          />
        )}
      </Box>
    </Stack>
  );

  const keyLevelsSwitch = (
    <Box sx={{ marginTop: '-4px', paddingTop: '10px' }} key="keyLevelsSwitch">
      <SGTooltip title="Overlay SpotGamma’s key levels for SPX, including the Call Wall, Put Wall, Hedge Wall, Key Gamma Strike, and SG Implied 1-Day Move.">
        <FormControlLabel
          control={
            <Switch
              checked={showKeyLevels}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                saveChanges({
                  hideLevels: !event.target.checked,
                })
              }
            />
          }
          label={<Typography textAlign="center">Key levels</Typography>}
          labelPlacement={'bottom'}
          sx={{ marginX: 0, alignSelf: 'center' }}
        />
      </SGTooltip>
    </Box>
  );

  const zoomOutButton = (
    <Box
      key="zoomOutButton"
      visibility={zoomData == null ? 'hidden' : 'visible'}
      sx={{
        alignSelf: 'center',
      }}
    >
      <Button
        sx={{ fontSize: '12px', textTransform: 'none' }}
        onClick={resetZoom}
        variant="text"
      >
        Zoom Out
      </Button>
    </Box>
  );

  const candleDurationSelect = (
    <SGSelect
      key={'candle_duration'}
      label={'Candle Duration'}
      multiple={false}
      value={candleDuration}
      setter={(newVal: number) =>
        saveChanges({
          candleDuration: newVal,
        })
      }
      options={[60, 5 * 60, 15 * 60]}
      optionsTextTransform={(duration: any) => `${duration / 60}m`}
      tooltipTransform={(duration: any) =>
        `Set the duration of each price candle to ${duration / 60} minute${
          duration / 60 > 1 ? 's' : ''
        }`
      }
      sx={{
        width: '75px',
        height: '40px',
        textAlign: 'center',
      }}
      selectTooltipProps={{
        placement: 'bottom',
        title:
          'Customize the price candles on the heatmap to intervals of 1 minute, 5 minutes, or 15 minutes',
      }}
    />
  );

  const zoomSlider = (
    <Box
      width={isMobile ? '80px' : '100px'}
      sx={{ paddingTop: '10px' }}
      key="zoomSlider"
    >
      <Slider
        aria-label="Zoom"
        valueLabelDisplay="auto"
        marks
        value={PRICE_BOUNDS.indexOf(priceBounds)}
        min={0}
        max={PRICE_BOUNDS.length - 1}
        onChange={handleBoundsChange}
        disabled={zoomData != null} // if zoomed in via plotly, the zoom slider does nothing
      />
      <SGTooltip title="Slide right to zoom in further on the heatmap, or left to zoom out.">
        <Typography
          sx={{
            fontSize: { xs: 12, sm: 13 },
            width: 1,
            textAlign: 'center',
            display: 'inline-block',
          }}
        >
          Zoom
        </Typography>
      </SGTooltip>
    </Box>
  );

  const lenseSelect = (
    <Stack direction="row">
      <SGSelect
        key="intraday-gamma-filters"
        label="Lenses"
        multiple={false}
        value={selectedLense}
        setter={(newVal: any) => {
          saveChanges({
            selectedLense: newVal,
          });
          setParams({ lense: newVal });
        }}
        options={(showAllSettings
          ? Object.values(IntradayGammaLense).filter(
              (k) => typeof k != 'string',
            )
          : PROD_GAMMA_LENSES
        ).sort((a: any, b: any) => a - b)}
        optionsTextTransform={(filter: any) =>
          IntradayFiltersReadable.get(filter) ?? ''
        }
        tooltipTransform={(filter: any) => IntradayFiltersTooltip.get(filter)}
        sx={{
          width: '125px',
          height: '40px',
          textAlign: 'center',
          alignSelf: 'center',
        }}
        selectTooltipProps={{
          placement: 'left',
          title:
            'Select from three models available for TRACE: Gamma, Delta Pressure, and Charm Pressure. Click on the adjacent (i) Info icon to learn more about each model.',
        }}
      />
      {!isMobile && (
        <InfoButton
          articleKey={`TRACE-${IntradayGammaLense[selectedLense]}`}
          tooltipTitle="Learn more about the current model displayed on the heatmap."
        />
      )}
    </Stack>
  );

  const timeCutoff = (
    <Box
      visibility={isLatestTimestamp ? 'hidden' : 'visible'}
      sx={{ alignSelf: 'center' }}
    >
      <SGTooltip
        title={
          'Limit the price candles and HIRO datapoints shown to only those up to your selected timestamp.'
        }
        placement="bottom-start"
      >
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={filterPrice}
              onChange={(evt) => {
                setFilterPrice(evt.target.checked);
              }}
              size="small"
            />
          }
          label={
            <Typography
              sx={{
                fontSize: 12,
                color: theme.palette.primary.main,
              }}
            >
              Time Cutoff
            </Typography>
          }
        />
      </SGTooltip>
    </Box>
  );

  const replaceTitleWithTools = screenWidthWithoutSidebar <= 900;
  const strikeBarInToolbar =
    !isMobile || showChartType === IntradayShowChartType.StrikeBars;
  const heatmapInToolbar =
    !isMobile || showChartType === IntradayShowChartType.Heatmap;

  const toolbarComponents = [
    [!replaceTitleWithTools, volGauge],
    [heatmapInToolbar, keyLevelsSwitch],
    [heatmapInToolbar, candleDurationSelect],
    [heatmapInToolbar, zoomSlider],
  ];

  const componentsInToolbar = toolbarComponents.map((arr) => arr[0] && arr[1]);

  const toolbar = (
    <Stack
      direction="row"
      gap={horizGap}
      marginTop="3px"
      justifyContent={!isMobile ? 'space-between' : 'center'}
      alignItems="center"
    >
      {strikeBarInToolbar && strikeBarSelectors}
      <Stack direction="row" gap={horizGap} alignItems="center">
        {componentsInToolbar}
        <Stack direction="row" alignItems="center">
          <ChartSizingButton
            onClick={() => setFullScreen((prev) => !prev)}
            icon={
              fullscreen ? <FullscreenExitSharpIcon /> : <FullscreenSharpIcon />
            }
            title={fullscreen ? 'Minimize TRACE' : 'Maximize TRACE'}
            tooltipPlacement="bottom-start"
            buttonSx={{ height: '40px', marginTop: '-1px' }}
          />
          {settingsPopout}
        </Stack>
      </Stack>
    </Stack>
  );

  const showClosedMsg =
    isLatestTradingDay(getCurrentDate()) &&
    getCurrentDate().hour() >= LAST_TRACE_UPDATE_HOUR_ET;

  const header = (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      justifyContent={isMobile ? 'center' : 'space-between'}
      alignItems="center"
      marginX={fullscreen ? '20px' : 0}
      marginTop={fullscreen ? '10px' : 0}
      sx={{ background: theme.palette.background.default }}
      gap="20px"
    >
      <Stack direction="row" gap="15px">
        {!replaceTitleWithTools && (
          <Typography fontSize={16}>
            {intradaySym} {IntradayFiltersReadable.get(selectedLense)} Exposure
            {INTRADAY_BAR_TITLES.get(strikeBarType)
              ? ` and ${INTRADAY_BAR_TITLES.get(strikeBarType)}`
              : ''}
          </Typography>
        )}
        {lastUpdateCheckAt != null && (
          <SGTooltip
            title={
              'To ensure you always have the latest data, TRACE checks for updates every few seconds, but there will usually only be a new update every 10-15 minutes. Updates will not necessarily occur at exact 10 minute intervals.'
            }
            placement="left"
          >
            <Stack alignSelf="center">
              <Typography
                color={theme.palette.trace.updateStatusColor}
                sx={{ fontSize: '11px' }}
              >
                Last checked for update at{' '}
                {lastUpdateCheckAt.tz(tz).format('HH:mm')}
              </Typography>
            </Stack>
          </SGTooltip>
        )}
        {showClosedMsg && lastUpdateCheckAt == null && (
          <Stack alignSelf="center">
            <Typography
              color={theme.palette.trace.updateStatusColor}
              sx={{ fontSize: '11px' }}
            >
              TRACE does not update after{' '}
              {getCurrentDate()
                .hour(LAST_TRACE_UPDATE_HOUR_ET)
                .tz(tz)
                .format('HH:00')}
            </Typography>
          </Stack>
        )}
      </Stack>

      <Stack direction="row" gap={horizGap}>
        {replaceTitleWithTools && volGauge}
        {lenseSelect}
        {datePicker}
      </Stack>
    </Stack>
  );

  return (
    <Stack
      sx={{
        marginBottom: showAllSettings ? 0 : '15px',
        paddingX: isMobile ? 0 : '10px',
        margin: 'auto',
        width: 1,
      }}
      gap="0px"
    >
      {fullscreen && <HideSupportStyle />}
      <Box width={1} flexGrow={1} textAlign="center">
        {header}
      </Box>
      {toolbar}
      <Stack
        direction="row-reverse"
        gap="10px"
        height="23px"
        marginBottom="3px"
      >
        {zoomOutButton}
        {timeCutoff}
        {showAllSettings && (
          <Box width={1} display="flex" justifyContent="flex-end">
            <Select
              id="intraday-gamma-select"
              multiple
              value={parquetKeys}
              displayEmpty
              size="small"
              onChange={(event: SelectChangeEvent<string[]>) => {
                setParquetKeys(event.target.value as OIEntity[]);
              }}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return `Select an entity`;
                }
                return selected
                  .map((k) => OIENTITY_READABLE.get(k as OIEntity)!)
                  .join(',');
              }}
              sx={{
                padding: 0,
                marginLeft: '50px',
                color: theme.palette.text.secondary,
              }}
            >
              {[...OIENTITY_READABLE.entries()].map(([key, label]) => (
                <MenuItem key={key} value={key}>
                  <Checkbox
                    checked={parquetKeys.indexOf(key as OIEntity) > -1}
                  />
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
            </Select>

            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={isInverted}
                  onChange={(evt) => {
                    setIsInverted(evt.target.checked);
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    textTransform: 'capitalize',
                    fontSize: {
                      xs: 12,
                      sm: 13,
                    },
                    color: theme.palette.primary.main,
                  }}
                >
                  Invert
                </Typography>
              }
            />
          </Box>
        )}
      </Stack>
    </Stack>
  );
};
