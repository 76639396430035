import { atom, DefaultValue, selector } from 'recoil';
import { Dayjs } from 'dayjs';
import {
  COMBINED_SIGNALS,
  DEFAULT_HIRO_SYMBOL,
  DefaultChartSettings,
  PREMARKET_SYMBOLS,
} from '../config';
import {
  AlertData,
  HiroOverview,
  HiroStatistics,
  IndicesStatistics,
  HiroLense,
  TrendingData,
  HiroUpdatingType,
  hasDashAccess,
  DashAccess,
} from '../types';
import {
  selectedWatchlistState,
  userDashAccessState,
  userIsLoggedInState,
  userSettingsState,
} from './auth';
import { getQueryDate, isBloomberg, safeMerge } from '../util';
import { isEqual } from 'lodash';
import { hiroManifestState } from './bloomberg';
import { unpricedEquitiesState } from './equityhub';
import { recoilPersist } from 'recoil-persist';
import { GridColumnVisibilityModel } from '@spotgamma/x-data-grid-premium';
import {
  DEFAULT_TNS_COL_VISIBILITY,
  DEFAULT_TNS_FIELDS,
  DEFAULT_TNS_COL_SIZES,
  OptionsFeedFilterTab,
} from 'config/optionsFeed';
import {
  Filter,
  FilterItem,
  OptionsFeedColumnKey,
  OptionsFeedColumnSizes,
} from 'types/optionsFeed';
const { persistAtom } = recoilPersist();

const DEFAULT_HIRO_TABS = [DEFAULT_HIRO_SYMBOL];

export const trendingState = atom({
  key: 'hiro-trendingState',
  default: [] as TrendingData[],
});

// using this state subscribes users to re-renders every 30 secs when stocks is updated in App.tsx
// if you just need the symbols in hiro, use the hiroSymbolsState below
// otherwise, if you actually need the data in stocks, you probably want to re-render every 30 secs anyway

// Map ({instrument: data}) of all stocks in screener
export const stocksState_SUBSCRIBE_TO_POLLING_RENDER = selector<
  Map<string, HiroOverview>
>({
  key: 'hiro-stocksState',
  get: ({ get }) => {
    return get(_stocksInternalState);
  },
  set: ({ set, get }, newValue) => {
    const hiroSyms = get(webHiroSymbolsState);
    const combos = COMBINED_SIGNALS.keys();
    const newSymbols =
      newValue instanceof DefaultValue
        ? new Set<string>(combos)
        : new Set([
            ...combos,
            ...[...newValue.values()].map((o: HiroOverview) => o.instrument),
          ]);

    if (!isEqual(newSymbols, hiroSyms)) {
      set(webHiroSymbolsState, newSymbols);
    }
    if (!(newValue instanceof DefaultValue)) {
      const liveSymbol = [...newValue.values()].find(
        (stock) => stock.live,
      )?.instrument;
      set(_internalFreeHiroSymbolState, liveSymbol);
    }
    return set(_stocksInternalState, newValue);
  },
});

// this is set by the poller that sets stocks state. we should never be using this directly outside of this file
const _internalFreeHiroSymbolState = atom<string | undefined>({
  key: 'hiro-internalFreeHiroSymbolState',
  default: undefined,
});

export const freeSymbolState = selector<string | undefined>({
  key: 'hiroAndEquityHub-liveSymbolState',
  get: ({ get }) => {
    const freeHiroSymbol = get(_internalFreeHiroSymbolState);
    if (freeHiroSymbol != null) {
      return freeHiroSymbol;
    }
    const unpricedEquities = get(unpricedEquitiesState);
    return [...unpricedEquities.values()].find((equity) => equity.live)?.sym;
  },
});

// Map ({instrument: data}) of all stocks in screener
export const _stocksInternalState = atom<Map<string, HiroOverview>>({
  key: 'hiro-stocksInternalState',
  default: new Map(),
});

// all the symbols that we technically allow on hiro
export const hiroSymbolsState = selector<Set<string>>({
  key: 'hiro-hiroSymbolsState',
  get: ({ get }) => {
    const webHiroSymbols = get(webHiroSymbolsState);
    if (!isBloomberg()) {
      return webHiroSymbols;
    }

    const manifest = get(hiroManifestState);
    return manifest == null
      ? webHiroSymbols
      : new Set([...manifest.underlyingsSet, ...manifest.combosSet]);
  },
});

// only symbols allowed in OG web Hiro (~350 or so)
// differs from symbols we allow in bloomberg hiro (>5k)
// this gets set above in stocksState. should not be set elsewhere
export const webHiroSymbolsState = atom<Set<string>>({
  key: 'hiro-webHiroSymbolsState',
  default: new Set(),
});

export const selectedHiroStatisticsState = atom<HiroStatistics>({
  key: 'hiro-selectedHiroStatisticsState',
  default: {} as HiroStatistics,
});

export const indicesStatisticsState = atom<IndicesStatistics | undefined>({
  key: 'hiro-indicesStatisticsState',
  default: undefined,
});

export const chartExpandedState = atom<boolean>({
  key: 'hiro-chartExpandedState',
  default: false,
});

export const timespanState = atom<string>({
  key: 'hiro-timespanState',
  default: '1',
});

// HIRO Alert data for the HIRO symbol in view
export const hiroAlertsState = atom<AlertData[]>({
  key: 'hiro-alertsState',
  default: [],
});

// User setting whether to show alerts or now
export const hiroETHState = selector<boolean>({
  key: 'hiro-eth',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return (
      settings.hiro?.chartSettings?.showExtendedHours ??
      DefaultChartSettings.showExtendedHours
    );
  },
});

// User setting whether to show alerts or now
export const hiroViewAlertsState = selector<boolean>({
  key: 'hiro-viewAlerts',
  get: ({ get }) => {
    const userIsLoggedIn = get(userIsLoggedInState);
    const settings = get(userSettingsState);

    return (
      userIsLoggedIn &&
      (settings.hiro?.chartSettings?.showAlerts ??
        DefaultChartSettings.showAlerts)
    );
  },
});

export const lenseState = selector<{ [value in HiroLense]: number }>({
  key: 'hiro-lenses',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return (
      settings.hiro?.chartSettings?.selectedLenses ??
      DefaultChartSettings.selectedLenses
    );
  },
});

export const optionTypeState = selector<string>({
  key: 'hiro-optionTypeState',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return (
      settings.hiro?.chartSettings?.optionType ??
      DefaultChartSettings.optionType
    );
  },
});

export const sumWindowState = selector<number>({
  key: 'hiro-sumWindowState',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return (
      settings.hiro?.chartSettings?.sumWindow ?? DefaultChartSettings.sumWindow
    );
  },
});

export const hiroWatchlistState = selector<string[]>({
  key: 'hiro-hiroWatchlistState',
  get: ({ get }) => {
    const fullWatchlist = get(selectedWatchlistState);
    const hiroSyms = get(hiroSymbolsState);
    return [...fullWatchlist].filter((ticker) => hiroSyms.has(ticker));
  },
});

export const isOpenSettingsState = atom<boolean>({
  key: 'hiro-isOpenSettingsState',
  default: false,
});

export const queryDefaultDateSelector = selector<Dayjs>({
  key: 'hiro-queryDefaultDateSelector',
  get: ({ get }) => {
    const supportsPremarket =
      PREMARKET_SYMBOLS.has(DEFAULT_HIRO_SYMBOL) && get(hiroETHState);
    return getQueryDate(supportsPremarket);
  },
});

export const startQueryDateState = atom<Dayjs>({
  key: 'hiro-startQueryDateState',
  default: queryDefaultDateSelector,
});

export const endQueryDateState = atom<Dayjs>({
  key: 'hiro-endQueryDateState',
  default: queryDefaultDateSelector,
});

export const hiroTimeState = atom<Dayjs | undefined>({
  key: 'hiro-time',
  default: undefined,
});

export const selectedHiroTabsState = selector<string[]>({
  key: 'hiro-selectedHiroTabsState',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return settings.hiro?.tabs ?? DEFAULT_HIRO_TABS;
  },
  set: ({ set, get }, newValue) => {
    const settings = get(userSettingsState);
    const hiroTabs =
      newValue instanceof DefaultValue ? DEFAULT_HIRO_TABS : newValue;

    const newSettings = safeMerge(settings, { hiro: { tabs: hiroTabs } });
    return set(userSettingsState, newSettings);
  },
});

export const lastManuallySetDateState = atom<Dayjs | undefined>({
  key: 'hiro-lastManuallySetDateState',
  default: undefined,
});

export const hiroUpdatingTypeState = selector<HiroUpdatingType>({
  key: 'hiro-updatingTypeState',
  get: ({ get }) => {
    if (isBloomberg()) {
      return HiroUpdatingType.STREAMING;
    }
    const userDash = get(userDashAccessState);
    const settings = get(userSettingsState);
    const useStreaming =
      hasDashAccess(userDash, DashAccess.BETA) &&
      !settings.hiro?.chartSettings?.usePolling;
    return useStreaming ? HiroUpdatingType.STREAMING : HiroUpdatingType.POLLING;
  },
});

export const hiroActiveWatchlistsIdsState = atom<number[]>({
  key: 'hiro-activeWatchlistIds',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const hirotnsNewFilterState = atom<FilterItem[]>({
  key: 'hiroTns-newFilterState',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const hirotnsSavedFiltersState = atom<Filter[]>({
  key: 'hiroTns-savedFiltersState',
  default: [],
});

export const hirotnsActiveCustomFilterState = atom<Filter | undefined>({
  key: 'hiroTns-activeCustomFilterState',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});

export const hiroTnsFilterPanelOpenState = atom<boolean>({
  key: 'hiroTns-filterPanelOpenState',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const hiroTnsColumnsVisibilityState = atom<GridColumnVisibilityModel>({
  key: 'hiroTns-tnsColumnsVisibilityState',
  default: DEFAULT_TNS_COL_VISIBILITY,
  effects_UNSTABLE: [persistAtom],
});

export const hiroTnsColumnOrderState = atom<OptionsFeedColumnKey[]>({
  key: 'hiroTns-tnsColumnOrderState',
  default: DEFAULT_TNS_FIELDS,
  effects_UNSTABLE: [persistAtom],
});

export const hiroTnsColumnSizesState = atom<OptionsFeedColumnSizes>({
  key: 'hiroTns-tnsColumnSizesState',
  default: DEFAULT_TNS_COL_SIZES,
  effects_UNSTABLE: [persistAtom],
});

export const hiroTnsActiveTabState = atom<OptionsFeedFilterTab>({
  key: 'hiroTns-tnsActiveTabState',
  default: OptionsFeedFilterTab.NewFilter,
  effects_UNSTABLE: [persistAtom],
});

export const hiroLoggedOutBannerOpen = atom<boolean>({
  key: 'hiroTns-loggedOutBannerOpen',
  default: true,
});
