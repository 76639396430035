import { useMemo } from 'react';
import { dayjs, ET, getQueryDateFormatted } from '../../util/shared/date';
import { HEATMAP_FIRST_AVAILABLE_DATE } from '../../config';
import { IntradayGammaLense, ProductType } from '../../types';
import { useSetSym } from '../hiro';
import { greekForLense } from '../../util';
import { useRecoilValue } from 'recoil';
import { oiSelectedLenseState } from '../../states';

type useTraceParamsProps = {
  productType?: ProductType;
};

const VALID_SYMS = new Set(['SPX', 'VIX']);

export const useTraceParams = ({
  productType = ProductType.TRACE,
}: useTraceParamsProps) => {
  const selectedLenseFromSettings = useRecoilValue(oiSelectedLenseState);

  const { getParam, searchParams } = useSetSym();

  const selectedLense = useMemo(() => {
    const lenseParam = getParam('lense');
    const lense: any = lenseParam
      ? parseInt(lenseParam)
      : selectedLenseFromSettings;
    return IntradayGammaLense[lense]
      ? (lense as IntradayGammaLense)
      : selectedLenseFromSettings;
  }, [searchParams, selectedLenseFromSettings]);

  const selectedGreek = useMemo(
    () => greekForLense(selectedLense),
    [selectedLense],
  );

  const intradaySym = useMemo(() => {
    if (productType !== ProductType.INTERNAL_OPEN_INTEREST) {
      return 'SPX';
    }

    let sym = getParam('sym')?.toUpperCase() ?? '';
    return VALID_SYMS.has(sym) ? sym : 'SPX';
  }, [searchParams, productType]);

  // make this a str since if it was a dayjs directly it would always trigger
  // a re-render on any search param change, since we use shallow equality to determine if the
  // memo value is 'new'. create a second memo that depends on this string that is a dayjs object instead
  const intradayDateStr = useMemo(() => {
    const param = getParam('date');
    const newDate = dayjs.tz(param, ET);
    if (
      param == null ||
      !newDate?.isValid() ||
      newDate.isBefore(HEATMAP_FIRST_AVAILABLE_DATE, 'date')
    ) {
      return getQueryDateFormatted(true);
    }

    return param;
  }, [searchParams]);

  const intradayDate = useMemo(
    // always reference the date strings in ET timezone since these refer to market days
    () => dayjs.tz(intradayDateStr, ET),
    [intradayDateStr],
  );

  return { intradayDate, intradaySym, selectedGreek, selectedLense };
};
