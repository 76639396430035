import { EquityColumnGroup } from '../../types/equityhub';
import { OCCCategory } from '../../types/sentiment';

export const darkModeTheme = {
  palette: {
    action: {
      disabled: '#87888a',
    },
    sgGreen: '#00BBAA',
    background: {
      default: '#111316',
      paper: '#17191D',
    },
    text: {
      primary: '#ffffff',
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(255, 255, 255, 0.5)',
    },
    gray: '#87888a',
    shadows: {
      default: '0px 0px 2px #bdbdbd',
      paperBoxShadow: 'none',
    },
    button: {
      default: '#164241',
      hover: '#497574',
    },
    core: {
      price: '#7c94eb',
      put: '#007ad1',
      call: '#ff7f0e',
      translucentBG: 'rgba(25, 25, 25, 0.8)',
    },
    equityHub: {
      fieldColorMapping: {
        upx: '#7c94eb',
        maxfs: '#FF8FC0',
        keyg: '#cb7b27',
        keyd: '#22d92d',
        putctrl: '#F9F871',
        cws: '#22e3ec',
        pws: '#e3313a',
      },
      skew: {
        allFirst: '#A1B7FF',
        allSecond: '#A1B7FF',
        nextExpFirst: '#B6FF9F',
        nextExpSecond: '#B6FF9F',
      },
    },
    equityHubColumns: {
      [EquityColumnGroup.STATISTICS]: '#FFFFFF',
      [EquityColumnGroup.SG_KEY_DAILY_LEVELS]: '#5dd4c2',
      [EquityColumnGroup.DIRECTIONAL_INDICATORS]: '#d1d1d1',
      [EquityColumnGroup.VOLATILITY_INSIGHTS]: '#dbc270',
      [EquityColumnGroup.DARK_POOL_INDICATORS]: '#d97f5d',
    },
    scannerGrid: {
      mainHeader: '#1C1E21',
      pinnedColumn: '#1C1E21',
      pinnedRow: '#133d3b',
    },
    alerts: {
      hiroReversionTop: '#d97f5d',
      hiroReversionBottom: '#18bdac',
    },
    hiro: {
      signalBg: '#282c33',
      flow: '#6CF5BB',
      indices: {
        SPY: '#3ad89e',
        QQQ: '#59f1ff',
        IWM: '#FFC759',
      },
      bottomCandles: {
        absolute: '#939393',
      },
      lenses: {
        all: {
          call: '#d8723a',
          put: '#3a79d8',
          total: '#6467e8',
        },
        retail: {
          call: '#d8af3a',
          put: '#a90680',
          total: '#ff5e5e',
        },
        nextExp: {
          call: '#30aF20',
          put: '#90b6ff',
          total: '#18bdac',
        },
        price: {
          total: '#d0d0d0',
        },
      },
      priceLines: {
        upx: '#a0a0a0',
        keyg: '#83deff',
        keyd: '#8ea3de',
        cws: '#3aa0ff',
        pws: '#c88070',
        maxfs: '#dd3333',
        sig_high: '#c334d2',
        sig_low: '#c334d2',
      },
      grid: {
        lineColor: '#404040',
      },
    },
    sentiment: {
      occ: {
        [OCCCategory.EQUITY_CALLS]: '#e377c2',
        [OCCCategory.EQUITY_PUTS]: '#7f7f7f',
        [OCCCategory.ETF_CALLS]: '#bcbd22',
        [OCCCategory.ETF_PUTS]: '#17becf',
        [OCCCategory.INDEX_CALLS]: '#1f77b4',
        [OCCCategory.INDEX_PUTS]: '#ff7f0e',
      },
      historical: {
        upx: '#a0a0a0',
        max_g_strike: '#FFCD6F',
        put_wall_strike: '#00D6FF',
        call_wall_strike: '#FF85C0',
        gamma_index: '#a422e8',
      },
    },
    indices: {
      combos: {
        gamma: '#A1B7FF',
      },
      delta: {
        all: '#b8b8b8',
        nextExpMonthly: '#ffe0a1',
        nextExp: '#ffb4a1',
      },
      gammaVanna: {
        gamma: '#a1ffe4',
        vanna: '#dba1ff',
        delta: '#ffc88a',
        volforecast: '#a1ffba',
        nextExp: '#b8b8b8',
      },
      tilt: {
        upx: '#a0a0a0',
        gamma: '#3364ff',
        delta: '#2147b7',
      },
      zeroDte: {
        volume: '#a82aff',
        oi: '#83e6ff',
        oi_avg: '#ff0046',
      },
      equityRatio: {
        volume: '#d1d1d1',
        oi: '#83e6ff',
      },
      vol: {
        five_day_vol: '#FF0000',
        one_month_vol: '#00FF00',
        two_month_vol: '#0099ff',
        three_month_vol: '#FFA500',
        six_month_vol: '#FFFFFF',
      },
      maxVol: {
        price: '#FFFFFF',
        two_six_month: '#dba1ff',
        one_three_month: '#ffc88a',
      },
    },
    iVol: {
      termStructure: {
        date0: '#00BBAA',
        date1: '#7f7f7f',
        date2: '#d5d708',
        date3: '#48dceb',
        date4: '#1f77b4',
        date5: '#ff7f0e',
      },
      volSkew: {
        date0: '#00BBAA',
        date1: '#7f7f7f',
        date2: '#d5d708',
        date3: '#48dceb',
        date4: '#1f77b4',
        date5: '#ff7f0e',
      },
      fixedStrikeMatrix: {
        grayCell: '#BABBBC',
        lowValueCell: '#16191D',
        highValueCell: '#18BDAC',
        highlight: '#FFA500',
      },
    },
    resources: {
      earnings: {
        bmo: '#24a7ed',
        amc: '#bf30bf',
        other: '#bababa',
        label: '#ebebeb',
      },
    },
    compass: {
      buttonColor: '#c159ff',
    },
    trace: {
      heatmapSettings: {
        hiroColor: '#6c6fff',
        chartZeroColor: '#111316',
        candleUpFillColor: '#FFF',
        candleDownFillColor: 'rgba(25,25,25,0.25)',
        candleUpLineColor: '#a0a0a0',
        candleDownLineColor: '#a0a0a0',
        contourLineColor: '#bdbdbd',
        callColor: '#d8723a',
        putColor: '#3a79d8',
        lastCandleColor: '#ffffff',
      },
      strikeBarSettings: {
        tracker: {
          last: '#fff',
          thirtyMin: '#ffce12',
          hour: '#1aebf5',
          dailyMax: '#858585',
          dailyMin: '#858585',
        },
      },
      updateStatusColor: '#626262FF',
    },
  },
};
