import { SGTooltip } from '../core';
import {
  IconButton,
  SxProps,
  Button,
  useTheme,
  PopperPlacementType,
  TooltipProps,
} from '@mui/material';
import { ReactElement } from 'react';

type ChartSizingButtonProps = {
  withText?: boolean;
  onClick: () => void;
  icon: ReactElement;
  buttonSx?: SxProps;
  title: string;
  tooltipPlacement?: TooltipProps['placement'];
};
export const ChartSizingButton = ({
  withText,
  onClick,
  icon,
  buttonSx,
  title,
  tooltipPlacement,
}: ChartSizingButtonProps) => {
  const theme = useTheme();
  return (
    <SGTooltip
      title={withText ? undefined : title}
      placement={tooltipPlacement ?? 'bottom'}
    >
      {withText ? (
        <Button
          startIcon={icon}
          sx={{
            fontSize: '12px',
            textDecoration: 'none',
            textTransform: 'none',
            marginLeft: '5px',
            color: theme.palette.text.primary,
            ...buttonSx,
          }}
          onClick={onClick}
          size="medium"
        >
          {title}
        </Button>
      ) : (
        <IconButton
          sx={{
            fontSize: '12px',
            textDecoration: 'none',
            marginLeft: '5px',
            ...buttonSx,
          }}
          onClick={onClick}
          size="medium"
          color="primary"
        >
          {icon}
        </IconButton>
      )}
    </SGTooltip>
  );
};
