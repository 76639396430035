import { useEffect, useState, useRef, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { decode } from '@msgpack/msgpack';
import { userDetailsState } from 'states';
import { FilterItem, RawOptionFeedData } from 'types/optionsFeed';
import { getOptionFeedDataRow, satisfyFilters } from 'util/optionsFeed';
import { getCachedToken } from 'util/shared';
import { StreamType } from 'types';
import { throttle } from 'lodash';
import { DEFAULT_TNS_SYMBOLS, OPTIONS_FEED_MAX_ROWS } from 'config/optionsFeed';
import useLog from '../useLog';

const MAX_RETRIES = 3;

const STREAM_HOST =
  process.env.REACT_APP_STREAMING_HOST ?? 'dev.stream.spotgamma.com';

const useTnSWebsocket = (
  syms = DEFAULT_TNS_SYMBOLS,
  filters: FilterItem[] = [],
) => {
  const userDetails = useRecoilValue(userDetailsState);
  const [socketError, setSocketError] = useState<string | null>(null);
  const [rows, setRows] = useState<RawOptionFeedData[]>([]);
  const ws = useRef<WebSocket | null>(null);
  const retryTimeout = useRef<number | null>(null);
  const retryCount = useRef<number>(0);
  const bufferedDataRef = useRef<RawOptionFeedData[]>([]);
  const { nonProdDebugLog } = useLog('useTnSWebsocket');

  const handleNewData = useCallback(
    (newData: RawOptionFeedData[]) => {
      const filteredData = newData.filter((d) => satisfyFilters(d, filters));
      bufferedDataRef.current = [
        ...filteredData,
        ...bufferedDataRef.current,
      ].slice(0, OPTIONS_FEED_MAX_ROWS);
      throttledUpdateRows();
    },
    [filters],
  );

  // Throttle the rows update to avoid super frequent updates
  const throttledUpdateRows = useCallback(
    throttle(() => {
      setRows((prevRows) => {
        const updatedRows = [...bufferedDataRef.current, ...prevRows].slice(
          0,
          OPTIONS_FEED_MAX_ROWS,
        );
        bufferedDataRef.current = [];
        return updatedRows;
      });
    }, 1000),
    [],
  );

  useEffect(() => {
    return () => {
      throttledUpdateRows.cancel();
    };
  }, [throttledUpdateRows]);

  useEffect(() => {
    // Clear rows when filters change and prepare to receive new filtered data
    setRows([]);
    bufferedDataRef.current = [];
  }, [filters]);

  const cleanupWebSocket = useCallback(() => {
    if (ws.current) {
      ws.current.onopen = null;
      ws.current.onmessage = null;
      ws.current.onclose = null;
      ws.current.onerror = null;
      if (ws.current.readyState === WebSocket.OPEN) {
        ws.current.close();
      }
      ws.current = null;
    }
  }, []);

  const cleanupRetryTimeout = useCallback(() => {
    if (retryTimeout.current) {
      clearTimeout(retryTimeout.current);
      retryTimeout.current = null;
    }
  }, []);

  const sendSocketEvent = useCallback((msg: any) => {
    if (ws.current?.readyState !== WebSocket.OPEN) {
      nonProdDebugLog('Attempted to send msg but websocket is not open', msg);
      return;
    }
    ws.current?.send(JSON.stringify(msg));
  }, []);

  const socketSubscribe = useCallback(
    (symbols: string[]) => {
      nonProdDebugLog('Subscribing to symbols:', symbols);
      const msg = {
        action: 'subscribe',
        underlyings: symbols,
        stream_types: StreamType.FULL_ABSOLUTE_SIGNAL,
      };
      sendSocketEvent(msg);
    },
    [sendSocketEvent],
  );

  const authenticateAndConnect = useCallback(() => {
    setSocketError(null);
    cleanupWebSocket();
    cleanupRetryTimeout();

    const streamingToken = getCachedToken();
    if (!streamingToken || userDetails?.isInstitutional !== false) {
      nonProdDebugLog('Invalid or missing streaming token.');
      return;
    }

    ws.current = new WebSocket(
      `wss://${STREAM_HOST}/stream?token=${encodeURIComponent(streamingToken)}`,
    );

    ws.current.onopen = () => {
      console.log('WebSocket connection opened.');
      retryCount.current = 0;
      socketSubscribe(syms);
    };

    ws.current.onmessage = async (event: MessageEvent) => {
      const { data } = event;
      if (typeof data === 'string') {
        nonProdDebugLog('Received ack from server.');
      } else {
        const signalTuple: any = decode(await data.arrayBuffer(), {
          useBigInt64: true,
        });
        const [, signal] = signalTuple;
        const newRow = getOptionFeedDataRow(signal);
        handleNewData([newRow]);
      }
    };

    ws.current.onclose = () => {
      console.log('WebSocket connection closed.');
      if (retryCount.current < MAX_RETRIES) {
        retryCount.current += 1;
        retryTimeout.current = window.setTimeout(() => {
          console.log(
            `Attempting to reconnect... (Attempt ${retryCount.current})`,
          );
          authenticateAndConnect();
        }, 5000);
      } else {
        console.log('Max retry attempts reached. Connection failed.');
        cleanupRetryTimeout();
      }
    };

    ws.current.onerror = (error: Event) => {
      console.error('WebSocket error:', error);
      setSocketError('An error occurred during the WebSocket connection.');
    };
  }, [
    cleanupWebSocket,
    cleanupRetryTimeout,
    sendSocketEvent,
    socketSubscribe,
    userDetails,
    handleNewData,
    syms,
  ]);

  useEffect(() => {
    if (userDetails?.isInstitutional === false) {
      authenticateAndConnect();
    }

    return () => {
      cleanupWebSocket();
      cleanupRetryTimeout();
    };
  }, [userDetails, authenticateAndConnect]);

  return { error: socketError, rows };
};

export default useTnSWebsocket;
