import { createTheme, Theme, PaletteOptions } from '@mui/material/styles';

import { darkModeTheme } from './theme_definitions/darkModeTheme';
import { lightModeTheme } from './theme_definitions/lightModeTheme';
import { colorBlindModeTheme } from './theme_definitions/colorBlindModeTheme';
import { EquityColumnGroup } from '../types';
import { OCCCategory } from '../types/sentiment';

export enum ColorMode {
  DARK = 'Dark',
  LIGHT = 'Light',
  COLOR_BLIND = 'Color Blind Friendly',
}

export type HeatmapColorSettings = {
  hiroColor: string;
  candleUpFillColor: string;
  candleDownFillColor: string;
  candleUpLineColor: string;
  candleDownLineColor: string;
  contourLineColor: string;
  callColor: string;
  putColor: string;
  chartZeroColor: string;
  lastCandleColor: string;
};

export type StrikeBarColorSettings = {
  tracker: {
    last: string;
    thirtyMin: string;
    hour: string;
    dailyMax: string;
    dailyMin: string;
  };
};

export type TraceColorSettings = {
  heatmapSettings: HeatmapColorSettings;
  strikeBarSettings: StrikeBarColorSettings;
  updateStatusColor: string;
};

type CustomPaletteKeys = {
  shadows: {
    default: string;
    paperBoxShadow: string;
  };
  sgGreen: string;
  gray: string;
  core: {
    price: string;
    put: string;
    call: string;
    translucentBG: string;
  };
  button: {
    default: string;
    hover: string;
  };
  equityHub: {
    fieldColorMapping: {
      // must match FIELD_NAME_MAPPING keys
      upx: string;
      maxfs: string;
      keyg: string;
      keyd: string;
      cws: string;
      pws: string;
      putctrl: string;
    };
    skew: {
      nextExpFirst: string;
      nextExpSecond: string;
      allFirst: string;
      allSecond: string;
    };
  };
  equityHubColumns: {
    [EquityColumnGroup.STATISTICS]: string;
    [EquityColumnGroup.SG_KEY_DAILY_LEVELS]: string;
    [EquityColumnGroup.DIRECTIONAL_INDICATORS]: string;
    [EquityColumnGroup.VOLATILITY_INSIGHTS]: string;
    [EquityColumnGroup.DARK_POOL_INDICATORS]: string;
  };
  scannerGrid: {
    mainHeader: string;
    pinnedRow: string;
    pinnedColumn: string;
  };
  alerts: {
    hiroReversionTop: string;
    hiroReversionBottom: string;
  };

  hiro: {
    signalBg: string;
    flow: string;
    indices: {
      SPY: string;
      QQQ: string;
      IWM: string;
    };
    bottomCandles: {
      absolute: string;
    };
    lenses: {
      all: ChartLenseColorConfig;
      retail: ChartLenseColorConfig;
      nextExp: ChartLenseColorConfig;
      price: {
        total: string;
      };
    };
    priceLines: {
      upx: string;
      pws: string;
      keyg: string;
      keyd: string;
      cws: string;
      maxfs: string;
    };
    grid: {
      lineColor: string;
    };
  };
  sentiment: {
    occ: {
      [OCCCategory.EQUITY_CALLS]: string;
      [OCCCategory.EQUITY_PUTS]: string;
      [OCCCategory.ETF_CALLS]: string;
      [OCCCategory.ETF_PUTS]: string;
      [OCCCategory.INDEX_CALLS]: string;
      [OCCCategory.INDEX_PUTS]: string;
    };
    historical: {
      upx: string;
      max_g_strike: string;
      put_wall_strike: string;
      call_wall_strike: string;
      gamma_index: string;
    };
  };
  indices: {
    combos: {
      gamma: string;
    };
    delta: {
      all: string;
      nextExpMonthly: string;
      nextExp: string;
    };
    gammaVanna: {
      gamma: string;
      vanna: string;
      delta: string;
      volforecast: string;
      nextExp: string;
    };
    tilt: {
      upx: string;
      gamma: string;
      delta: string;
    };
    zeroDte: {
      volume: string;
      oi: string;
      oi_avg: string;
    };
    equityRatio: {
      volume: string;
      oi: string;
    };
    vol: {
      five_day_vol: string;
      one_month_vol: string;
      two_month_vol: string;
      three_month_vol: string;
      six_month_vol: string;
    };
    maxVol: {
      price: string;
      two_six_month: string;
      one_three_month: string;
    };
  };
  iVol: {
    termStructure: {
      [date: string]: string;
    };
    volSkew: {
      [date: string]: string;
    };
    fixedStrikeMatrix: {
      grayCell: string;
      lowValueCell: string;
      highValueCell: string;
      highlight: string;
    };
  };
  resources: {
    earnings: {
      bmo: string;
      amc: string;
      other: string;
      label: string;
    };
  };
  compass: {
    buttonColor: string;
  };
  trace: TraceColorSettings;
};

declare module '@mui/material/styles' {
  interface Theme {
    colorMode: ColorMode;
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    colorMode: ColorMode;
    status?: {
      danger?: string;
    };
  }

  interface Palette extends CustomPaletteKeys {}
  interface PaletteOptions extends CustomPaletteKeys {}
}

type ChartLenseColorConfig = {
  call: string;
  put: string;
  total: string;
};

type CustomColorModeDefinition = {
  palette: PaletteOptions;
};

const definitionForColorMode = (
  colorMode: ColorMode,
): CustomColorModeDefinition => {
  switch (colorMode) {
    case ColorMode.LIGHT:
      return lightModeTheme;
    case ColorMode.COLOR_BLIND:
      return colorBlindModeTheme;
    case ColorMode.DARK:
    default:
      return darkModeTheme;
  }
};

export let themeForMode = (colorMode: ColorMode): Theme => {
  const definition = definitionForColorMode(colorMode);
  return createTheme({
    colorMode: colorMode,
    spacing: [0, 2, 4, 8, 12, 14, 16, 24, 32, 64],
    palette: {
      primary: {
        main: '#18bdac',
      },
      secondary: {
        main: '#6467E8',
      },
      success: {
        main: '#22CC63',
      },
      error: {
        main: '#FF5E5E',
      },
      warning: {
        main: '#ffcd5e',
      },
      info: {
        main: '#878787',
      },
      divider: '#0f1214',
      ...definition.palette,
    },
    typography: {
      fontSize: 12,
      fontWeightLight: 400,
      body1: {
        color: definition.palette.text!.primary,
        display: 'inline',
      },
      body2: {
        color: '#636569',
        display: 'inline',
      },
      h1: {
        fontSize: 24,
        fontWeight: 500,
        fontFamily: 'Satoshi Complete',
      },
      fontFamily: 'SF Pro Display',
      h2: {
        fontSize: 16,
        fontFamily: 'Satoshi Complete',
      },
      h3: {
        fontSize: 14,
        fontFamily: 'Satoshi Complete',
      },
      h4: {
        fontSize: 12,
      },
      h5: {
        fontSize: 11,
      },
      button: {
        fontSize: 14,
        fontWeight: 500,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            fontSize: '1rem',
            ':disabled': {
              color: definition.palette.text?.disabled,
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            borderRadius: 3,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: '#0e7167',
          },
          label: {
            fontSize: '12px',
            color: '#ffffff',
          },
          deleteIcon: {
            color: 'rgba(255,255,255,0.25)',
          },
        },
      },
    },
  });
};

// https://maketintsandshades.com/#18bdac
