import {
  IntradayGammaLense,
  IntradayStrikeBarType,
  StrikeBarsDailyTrackerEntry,
} from '../types';
import dayjs from 'dayjs';
import { ComboSymbol } from './hiro';

export const IntradayFiltersReadable = new Map([
  [IntradayGammaLense.GAMMA, 'Gamma'],
  [IntradayGammaLense.DELTA, 'Delta'],
  [IntradayGammaLense.DELTA_DIRECTIONAL, 'Delta Pressure'],
  [IntradayGammaLense.GAMMA_DIRECTIONAL, 'Gamma Pressure'],
  [IntradayGammaLense.GAMMA_COLOR, 'Color (Gamma)'],
  [IntradayGammaLense.DELTA_CHARM_DIRECTIONAL, 'Charm Pressure'],
  [IntradayGammaLense.DELTA_END_DIFF, 'Delta Relative Close'],
]);

export const IntradayFiltersAxisLabels = new Map([
  [IntradayGammaLense.GAMMA, 'Gamma ($ Notional)'],
  [IntradayGammaLense.DELTA, 'Delta ($ Notional)'],
  [IntradayGammaLense.DELTA_DIRECTIONAL, 'Delta ($ Notional)'],
  [IntradayGammaLense.GAMMA_DIRECTIONAL, 'Gamma ($ Notional)'],
  [IntradayGammaLense.GAMMA_COLOR, 'Gamma ($ Notional)'],
  [IntradayGammaLense.DELTA_CHARM_DIRECTIONAL, 'Delta ($ Notional)'],
  [IntradayGammaLense.DELTA_END_DIFF, 'Delta ($ Notional)'],
]);

export const IntradayFiltersToParamKeys = new Map<
  IntradayGammaLense | IntradayStrikeBarType,
  string
>([
  [IntradayGammaLense.GAMMA, 'gamma'],
  [IntradayGammaLense.DELTA, 'delta'],
  [IntradayGammaLense.GAMMA_COLOR, 'color'],
  [IntradayGammaLense.DELTA_CHARM_DIRECTIONAL, 'charm'],
  [IntradayStrikeBarType.OI, 'strike_oi'],
  [IntradayStrikeBarType.OI_NET, 'strike_oi'],
  [IntradayStrikeBarType.GAMMA, 'strike_gex'],
  [IntradayGammaLense.DELTA_DIRECTIONAL, 'rel_delta'],
  [IntradayGammaLense.GAMMA_DIRECTIONAL, 'rel_gamma'],
]);

export const IntradayFiltersStatsInnerKeys = new Map<
  IntradayGammaLense | IntradayStrikeBarType,
  string
>([
  [IntradayStrikeBarType.OI_NET, 'net'],
  [IntradayStrikeBarType.GAMMA, 'gamma'],
]);

export const HEATMAP_FIRST_AVAILABLE_DATE = dayjs('2024-06-07');
export const HEATMAP_DEFAULT_POS_COLOR = '#771ee8';
export const HEATMAP_DEFAULT_NEG_COLOR = '#f51257';

export const HEATMAP_DEFAULT_SPX_HIRO_SYM = ComboSymbol.SPX_EQUITIES;

export enum IntradayShowChartType {
  StrikeBars,
  Heatmap,
  Both,
}

// order here is how they are displayed
export const PROD_GAMMA_LENSES = [
  IntradayGammaLense.GAMMA,
  IntradayGammaLense.DELTA_DIRECTIONAL,
  IntradayGammaLense.DELTA_CHARM_DIRECTIONAL,
];

export const HEATMAP_TRACE_NAME = 'heatmap';
export const PRICE_CANDLES_TRACE_NAME = 'price';
export const HIRO_TRACE_NAME = 'hiro';

export const DEFAULT_MAX_AGE_PADDING_MS = 10_000; // add 10 secs to max age to ensure there is data to poll
export const DEFAULT_MAX_AGE_MS = 10 * 60 * 1_000; // 5 mins
export const LAST_TRACE_UPDATE_HOUR_ET = 18;

export const INTRADAY_BAR_TITLES = new Map([
  [IntradayStrikeBarType.GAMMA, 'GEX by Strike'],
  [IntradayStrikeBarType.OI, 'OI by Strike'],
  [IntradayStrikeBarType.OI_NET, 'Net OI by Strike'],
]);
